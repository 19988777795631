const serverUrl = process.env.REACT_APP_SERVER_URL;

const fetchCategories = async (token, callback) => {
  try {
    const request = {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };
    request.headers.Authorization = `Bearer ${token}`;
    const response = await fetch(`${serverUrl}/category`, request);
    const categories = await response.json();
    if (callback) callback(categories);
  } catch (error) {
    console.log(error.message);
  }
};

const fetchAlerts = async (token, callback) => {
  try {
    const request = {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    };
    request.headers.Authorization = `Bearer ${token}`;
    const response = await fetch(`${serverUrl}/alert`, request);
    const alerts = await response.json();
    if (callback) callback(alerts);
  } catch (error) {
    console.log(error.message);
  }
};

const sendAlert = async (title, body, categoryId, token, success, failure) => {
  try {
    const request = {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({ title: title, body: body, category: categoryId }),
      headers: {
        "Content-Type": "application/json",
      },
    };
    request.headers.Authorization = `Bearer ${token}`;
    const response = await fetch(`${serverUrl}/alert`, request);
    if (response.status == 201) {
      if (success) success();
    } else {
      if (failure) failure(response.statusText + " (" + response.status + ")");
    }
  } catch (error) {
    console.log(error.message);
  }
};

const deleteAlert = async (id, token, success, failure) => {
  try {
    const request = {
      credentials: "include",
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };
    request.headers.Authorization = `Bearer ${token}`;
    const response = await fetch(`${serverUrl}/alert/${id}`, request);
    if (response.status == 204) {
      if (success) success();
    } else {
      if (failure) failure(response.statusText + " (" + response.status + ")");
    }
  } catch (error) {
    console.log(error.message);
  }
};

export { fetchCategories, fetchAlerts, sendAlert, deleteAlert };
