import React from "react";

import { useDispatch } from "../modules/dispatch-api";
import AlertList from "./AlertList";
import LoadingModal from "./LoadingModal";
import AlertSubmissionForm from "./AlertSubmissionForm";
import "./DispatchWindow.css";
import Header from "./Header";

const DispatchWindow = ({ onSuccess }) => {
  const { getAlerts, sendAlert } = useDispatch();
  return (
    <div className="DispatchWindow">
      <div className="DispatchWindow__submitPanel">
        <Header />
        <AlertSubmissionForm
          onSubmit={(alert) => sendAlert(alert.title, alert.body, alert.categoryId, onSuccess)}
        />
      </div>
      <div className="DispatchWindow__managePanel">
        <h3>Manage Alerts</h3>
        <AlertList alerts={getAlerts()} />
      </div>
    </div>
  );
};

export default DispatchWindow;
