import { useEffect, useState } from "react";

import "./App.css";
import DispatchWindow from "./components/DispatchWindow";
import { useDispatch } from "./modules/dispatch-api";
import LoadingModal from "./components/LoadingModal";

function App() {
  const { isLoading, isAuthenticated, loadingMessage } = useDispatch();
  const [success, setSuccess] = useState(false);

  return (
    <div className="App">
      {isLoading() ? <LoadingModal message={loadingMessage} /> : null}
      {success ? <LoadingModal message={"Message sent successfully!"} /> : null}
      {isAuthenticated ? (
        <DispatchWindow
          onSuccess={() => {
            setSuccess(true);
            setTimeout(() => setSuccess(false), 1000);
          }}
        />
      ) : null}
    </div>
  );
}

export default App;
