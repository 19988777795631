import React from "react";

import "./AlertListItem.css";
import deleteIcon from "../assets/trash.svg";

const AlertListItem = ({ alert, onDelete }) => {
  const date = new Date(alert.timestamp * 1000);

  return (
    <div className="AlertListItem">
      <div className="AlertListItem__details">
        <strong>
          {date.getMonth() + 1}/{date.getDate()}/{date.getFullYear()}
        </strong>
        {alert.title}
      </div>
      <button
        title="Delete"
        className="AlertListItem__delete"
        onClick={() => {
          if (onDelete) onDelete(alert.id);
        }}
      >
        <img src={deleteIcon} />
      </button>
    </div>
  );
};

export default AlertListItem;
