import React from "react";

import "./Header.css";
import headerLogo from "../assets/header-logo.jpg";
import logoutIcon from "../assets/logout.svg";
import { useDispatch } from "../modules/dispatch-api";

const Header = () => {
  const { logout } = useDispatch();
  return (
    <div className="Header">
      <img className="Header__logo" src={headerLogo} />
      <button
        title="Log Out"
        className="Header__logout"
        onClick={() => logout({ returnTo: window.location.origin })}
      >
        <img src={logoutIcon} />
      </button>
    </div>
  );
};

export default Header;
