import React from "react";

import AlertListItem from "./AlertListItem";
import "./AlertList.css";
import { useDispatch } from "../modules/dispatch-api";

const AlertList = ({ alerts }) => {
  const { deleteAlert } = useDispatch();

  return (
    <div data-testid="AlertList" className="AlertList">
      <div className="AlertList__items">
        {alerts.map((alert) => (
          <AlertListItem key={alert.id} alert={alert} onDelete={(id) => deleteAlert(id)} />
        ))}
      </div>
    </div>
  );
};

export default AlertList;
