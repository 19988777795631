import React from "react";

import "./LoadingModal.css";

const LoadingModal = ({ message }) => {
  return (
    <div className="LoadingModal">
      <div className="LoadingModal__window">{message}</div>
    </div>
  );
};

export default LoadingModal;
