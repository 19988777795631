import { Auth0Provider } from "@auth0/auth0-react";
import { DispatchProvider } from "./dispatch-context";

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
const origin = window.location.origin;

const AuthWrapper = ({ children }) => {
  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={origin.includes("localhost") ? "http://localhost:3000" : origin}
      audience={audience}
    >
      <DispatchProvider>{children}</DispatchProvider>
    </Auth0Provider>
  );
};

export { AuthWrapper as DispatchProvider };

export { useDispatch, DispatchContext } from "./dispatch-context";
