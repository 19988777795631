import React, { useState } from "react";
import { useDispatch } from "../modules/dispatch-api";
import "./AlertSubmissionForm.css";

const AlertSubmissionForm = ({ onSubmit }) => {
  const [category, setCategory] = useState(1);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const { getCategories } = useDispatch();

  const submit = () => {
    if (onSubmit) {
      onSubmit({
        categoryId: category,
        title: title,
        body: body.replace(/(?:\r\n|\r|\n)/g, "<br>"),
      });
      setBody("");
      setTitle("");
    }
  };

  return (
    <form
      className="AlertSubmissionForm"
      onKeyPress={(e) => {
        if (e.key == "Enter") {
        }
      }}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <label htmlFor="category">Category</label>
      <select
        data-testid="category"
        name="category"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
      >
        {getCategories().map((category) => (
          <option key={category.name} value={category.id}>
            {category.name}
          </option>
        ))}
      </select>
      <label htmlFor="title">Title</label>
      <input
        name="title"
        type="text"
        placeholder="Title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <label htmlFor="body">Body</label>
      <textarea
        name="body"
        placeholder="Body"
        value={body}
        rows={5}
        onChange={(e) => setBody(e.target.value)}
      />
      <p>
        *Be careful to check spelling before sending. Clicking Send will send a push notification to
        all users who registered to receive them.
      </p>
      <button type="button" className="button" onClick={submit}>
        Send
      </button>
    </form>
  );
};

export default AlertSubmissionForm;
