import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useContext, useEffect } from "react";

import * as api from "./api";

const DispatchContext = React.createContext();

const DispatchProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState("Loading...");
  const [token, setToken] = useState(null);
  const auth0 = useAuth0();

  useEffect(() => {
    if (!auth0.isAuthenticated && !auth0.isLoading) {
      auth0.loginWithRedirect();
    } else if (auth0.isAuthenticated) {
      (async () => {
        setToken(await auth0.getAccessTokenSilently());
      })();
    }
  }, [auth0.isAuthenticated, auth0.isLoading]);

  useEffect(() => {
    if (token != null) refreshAlerts();
  }, [token]);

  const getAlerts = (categoryId = null, limit = -1) => {
    const results =
      categoryId == null ? alerts : alerts.filter((alert) => alert.category == categoryId);
    return limit == -1 ? results : results.slice(0, limit);
  };

  const getCategories = () => {
    return categories;
  };

  const isLoading = () => {
    return loading;
  };

  const refreshAlerts = (callback) => {
    setLoading(true);
    api.fetchCategories(token, (categories) => {
      setCategories(categories);
      api.fetchAlerts(token, (alerts) => {
        setAlerts(alerts);
        setLoading(false);
        if (callback) callback(callback);
      });
    });
  };

  const sendAlert = (title, body, categoryId, callback) => {
    setLoadingMessage("Sending...");
    setLoading(true);
    api.sendAlert(
      title,
      body,
      categoryId,
      token,
      () => {
        refreshAlerts(callback);
      },
      (message) => {
        showError(message);
      }
    );
  };

  const deleteAlert = (id, callback) => {
    setLoadingMessage("Deleting...");
    setLoading(true);
    api.deleteAlert(
      id,
      token,
      () => {
        refreshAlerts(callback);
      },
      (message) => {
        showError(message);
      }
    );
  };

  const showError = (message) => {
    setLoadingMessage("Error: " + message);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  return (
    <DispatchContext.Provider
      value={{
        getAlerts,
        getCategories,
        isLoading,
        refreshAlerts,
        deleteAlert,
        sendAlert,
        loadingMessage,
        login: auth0.loginWithRedirect,
        logout: auth0.logout,
        isAuthenticated: auth0.isAuthenticated,
      }}
    >
      {children}
    </DispatchContext.Provider>
  );
};

export const useDispatch = () => {
  return useContext(DispatchContext);
};

export { DispatchContext, DispatchProvider };
